<template>
  <div class="row">
    <div class="col-4">
      <h6>{{ $t('system.add_new_term') }}</h6>
      <el-form
          :size="size"
          class="mt-4 form"
      >
        <el-form-item :label="$t('system.range')">
          <el-date-picker
              :end-placeholder="$t('system.to')"
              :start-placeholder="$t('system.from')"
              class="w-100"
              format="yyyy-MM-dd"
              type="daterange"
              v-model="formDateRange"
              value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item :label="$t('system.range')">
          <el-input
              type="textarea"
              v-model="form.reason"
          />
        </el-form-item>
        <el-form-item class="d-flex justify-content-end">
          <el-button
              :loading="saveLoading"
              @click="save"
              type="primary"
          >
            {{ $t('system.save') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="col-8">
      <DataTable
          :headers="unavailableTermsHeaders"
          :loading="fetchLoading || removeLoading"
          :model="unavailableTerms"
          :pagination="false"
          :params.sync="unavailableTermsParams"
      >
        <template v-slot:item.options="{row}">
          <div class="d-flex mx-2 justify-content-center">
            <!--          <el-button-->
            <!--              type="info"-->
            <!--              size="mini"-->
            <!--              class="mr-2"-->
            <!--              @click.stop="openEditForm(row.id)"-->
            <!--          >-->
            <!--            {{$t('system.edit')}}-->
            <!--          </el-button>-->
            <el-popconfirm
                :cancelButtonText="$t('system.no')"
                :confirmButtonText="$t('system.yes')"
                :title="$t('system.are_you_sure')"
                @onConfirm="remove(row)"
                iconColor="red"
            >
              <el-button
                  size="mini"
                  slot="reference"
                  type="danger"
              >
                {{ $t('system.delete') }}
              </el-button>
            </el-popconfirm>
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DataTable from '@/components/Table/DataTable.vue';
import notification from '../../../notification/notify';

const emptyForm = {
  from: null,
  to: null,
  reason: '',
};

export default {
  name: 'VehicleUnavailableTerms',
  components: {DataTable},
  props: {
    vehicleId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    size: 'mini',
    saveLoading: false,
    fetchLoading: false,
    removeLoading: false,
    unavailableTermsHeaders: [
      {
        key: 'from',
        label: 'system.start_date',
      },
      {
        key: 'to',
        label: 'system.due_date',
      },
      {
        key: 'reason',
        label: 'system.reason',
      },
      {
        key: 'options',
        label: 'system.options',
        sort: false,
      },
    ],
    unavailableTerms: [],
    unavailableTermsParams: {
      column: 'id',
      direction: 'desc',
    },
    form: {...emptyForm},
  }),
  computed: {
    formDateRange: {
      set(value) {
        const [start, end] = value || [null, null];
        this.form.from = start;
        this.form.to = end;
      },
      get() {
        if (this.form.from && this.form.to) {
          return [this.form.from, this.form.to];
        }
        return [];
      },
    },
  },
  created() {
    this.fetchUnavailableTerms();
  },
  methods: {
    async save() {
      this.saveLoading = true;
      try {
        await axios.post(`workorders/add_availability_period/${this.vehicleId}`, this.form);
        await this.fetchUnavailableTerms();
        this.form = {...emptyForm};
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_save'),
            'error');
      } finally {
        this.saveLoading = false;
      }
    },
    async remove(row) {
      try {
        this.removeLoading = true;
        await axios.delete(`workorders/remove_availability_period/${row.id}`);
        this.fetchUnavailableTerms();
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_remove'),
            'error');
      } finally {
        this.removeLoading = false;
      }
    },
    async fetchUnavailableTerms() {
      try {
        this.fetchLoading = true;
        const {data} = await axios.get(`workorders/get_availability_periods/${this.vehicleId}`);
        this.unavailableTerms = data.data || data;
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.fetchLoading = false;
      }
    },
  },
};
</script>

<style
    lang="scss"
    scoped
>
.form {
  padding: 15px 15px 1px;
  background: #F2F4F6;
  border-radius: 15px;
}
</style>
